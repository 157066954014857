import React, { useState, useEffect } from 'react';

const App = () => {
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Belirli bir süre sonra yönlendirme yap
      if (loading) {
        window.location.href = 'https://perfumeatelier.com.tr';
      }
    }, 10000); // 10 saniye bekle

    navigator.geolocation.getCurrentPosition(
      (position) => {
        clearTimeout(timeoutId); // Eğer konum alınırsa timeout'u temizle
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
          .then((response) => response.json())
          .then((data) => {
            const countryName = data.address.country;
            setCountry(countryName);
            setLoading(false); // Loading state'i false yap

            setTimeout(() => {
              countryName === 'Birleşik Krallık'
                ? window.location.href = 'https://perfumeatelier.co.uk'
                : window.location.href = 'https://perfumeatelier.com.tr';
            }, 1000);
          });
      },
      (error) => {
        // Konuma izin verilmediğinde veya başka bir hata oluştuğunda çalışacak
        clearTimeout(timeoutId); // Timeout'u temizle
        window.location.href = 'https://perfumeatelier.com.tr';
      }
    );
  }, []);

  return (
    <div>
      {loading ? (
        <p>Yükleniyor...</p>
      ) : (
        <p>{country} web sitesine yönlendiriliyorsunuz</p>
      )}
    </div>
  );
};

export default App;
